<form [formGroup]="formGroup" (ngSubmit)="finalizeInvoice()">
  <div dougsModalTitle>
    <h6>Vous allez finaliser votre première facture !</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent class="finalization-content">
    <img src="images/emoji/party-popper.png" alt="Party Popper" />
    <h3 class="my-16">Bravo {{ data.firstName }},</h3>
    <p class="mb-32">vous allez finaliser votre première facture !</p>
    <dougs-divider></dougs-divider>
    <h6 class="mt-32 mb-16">Vous devez choisir...</h6>
    <p class="small mb-4">
      <b>...le numéro de votre première facture :</b>
    </p>
    <dougs-form-field class="finalization-content__input" [noMargin]="true">
      <input type="number" dougsFormFieldControl formControlName="number" />
      <span *ngIf="formService.isControlInvalid(number)" dougsFormFieldError>
        <span *ngIf="number.hasError('min')">Le numéro doit être un entier positif</span>
        <span *ngIf="number.hasError('max')">Le numéro ne peut pas être supérieur à 2 000 000 000</span>
        <span *ngIf="number.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <p class="tiny mb-24 mt-8">
      Nous vous recommandons de choisir 1, sauf si vous avez déjà émis des factures sur un autre logiciel.
      <a
        *ngIf="(companyServicesStateService.services$ | async)?.features.hasInvoicingHelp"
        rel="noopener"
        href="https://aide.dougs.fr/fr/articles/4632300-comment-numeroter-et-dater-mes-factures"
        target="_blank"
      >
        En savoir plus
      </a>
    </p>
    <p class="small mb-4">
      <b>...et sa date :</b>
    </p>
    <dougs-form-field class="finalization-content__input">
      <input
        type="text"
        dougsFormFieldControl
        formControlName="date"
        mask="00/00/0000"
        [dougsDatepicker]="datepicker"
      />
      <span dougsFormFieldSuffix>
        <i class="fal fa-calendar"></i>
      </span>
      <span *ngIf="formService.isControlInvalid(date)" dougsFormFieldError>
        <span *ngIf="date.hasError('date')">La date n'est pas au format jj/mm/aaaa</span>
        <span *ngIf="date.hasError('required')">Ce champ est requis</span>
        <span *ngIf="date.hasError('minDate') && !date.hasError('date')">
          La date doit être avant ou égale à la date du jour
        </span>
      </span>
    </dougs-form-field>
    <p class="tiny mb-32 mt-8">
      Vos prochaines factures ne pourront pas être émises avant cette date.
      <a
        *ngIf="(companyServicesStateService.services$ | async)?.features.hasInvoicingHelp"
        rel="noopener"
        href="https://aide.dougs.fr/fr/articles/4632300-comment-numeroter-et-dater-mes-factures"
        target="_blank"
      >
        En savoir plus
      </a>
    </p>
    <p class="small mb-32">Une fois finalisée, vous ne pourrez plus modifier votre facture.</p>
    <dougs-divider></dougs-divider>
    <dougs-datepicker [options]="{ maxDate: maxDate }" #datepicker></dougs-datepicker>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Je préfère vérifier une dernière fois</dougs-button>
    <dougs-button type="submit">Finaliser la facture</dougs-button>
  </div>
  <dougs-loader-fullpage *ngIf="isLoading">Finalisation en cours...</dougs-loader-fullpage>
</form>
