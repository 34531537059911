<div dougsModalTitle>
  <h6>Vous allez finaliser votre facture !</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="finalization-content">
  <img src="images/emoji/rocket.png" alt="Rocket" />
  <h3 class="my-16">Vous allez finaliser votre facture !</h3>
  <p class="mb-16">
    Elle portera le n°
    <b>{{ (data.invoice?.numberPrefix ?? '') + data.invoiceNumber }}</b>
    <br />
    pour un total TTC à régler de
    <b>{{ data.totalAmount | currency: 'EUR' }}</b>
    <br />
    et sera émise à la date du
    <b>{{ data.date | date: 'dd/MM/yyyy' }}</b>
    .
  </p>
  <p class="tiny">
    Une fois finalisée, vous ne pourrez plus modifier ou supprimer votre facture sans procéder à un avoir. Vos
    prochaines factures ne pourront avoir une date antérieure au {{ data.date | date: 'dd/MM/yyyy' }} (date de cette
    facture).
  </p>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Je préfère vérifier une dernière fois</dougs-button>
  <dougs-button (click)="finalizeInvoice()">Finaliser la facture</dougs-button>
</div>
<dougs-loader-fullpage *ngIf="isLoading">Finalisation en cours...</dougs-loader-fullpage>
