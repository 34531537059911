import { CurrencyPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import {
  ButtonComponent,
  DougsDatePipe,
  LoaderFullpageComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { SalesInvoiceDraft } from '@dougs/sales-invoice/dto';
import { SalesInvoiceStateService } from '@dougs/sales-invoice/shared';

@Component({
  selector: 'dougs-finalization-modal',
  templateUrl: './finalization-modal.component.html',
  styleUrls: ['./finalization-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ButtonComponent,
    NgIf,
    LoaderFullpageComponent,
    CurrencyPipe,
    DougsDatePipe,
  ],
})
export class FinalizationModalComponent {
  isLoading = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      invoice: SalesInvoiceDraft;
      invoiceNumber: number;
      date: Date;
      totalAmount: number;
    },
    private readonly modalRef: ModalRef<SalesInvoiceDraft | null, void>,
    private readonly invoiceStateService: SalesInvoiceStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly metricsService: MetricsService,
  ) {}

  async finalizeInvoice(): Promise<void> {
    this.isLoading = true;
    const finalizedInvoice: SalesInvoiceDraft | null = await this.invoiceStateService.finalizeInvoice(
      this.data.invoice,
    );

    if (finalizedInvoice) {
      this.metricsService.pushMixpanelEvent('Invoicing finalised Invoice Success');
      this.metricsService.pushIntercomEvent('Invoicing finalised Invoice Success');
      this.modalRef.close(finalizedInvoice);
    }

    this.isLoading = false;
    this.cdr.markForCheck();
  }
}
