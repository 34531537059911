<div dougsModalTitle>
  <h6>On y est presque !</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-32">
    Quelques erreurs se sont glissées dans votre {{ data.type === 'invoice' ? 'facture' : 'proposition commerciale' }}.
    <br />
    Merci de les corriger afin de lancer la finalisation.
  </p>
  <ul>
    <li *ngFor="let invoiceError of data.invoiceErrors">
      {{ invoiceError.message }}
    </li>
  </ul>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button dougsModalClose>
    Corriger la {{ data.type === 'invoice' ? 'facture' : 'proposition commerciale' }}
  </dougs-button>
</div>
