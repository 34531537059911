import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { format, isBefore, isEqual, isValid, parse } from 'date-fns';
import { NgxMaskDirective } from 'ngx-mask';
import { FormService } from '@dougs/core/form';
import { MetricsService } from '@dougs/core/metrics';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  DatepickerComponent,
  DatepickerDirective,
  DividerComponent,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LoaderFullpageComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { Invoicer, SalesInvoiceDraft } from '@dougs/sales-invoice/dto';
import { SalesInvoicerStateService, SalesInvoiceStateService } from '@dougs/sales-invoice/shared';

@Component({
  selector: 'dougs-first-finalization-modal',
  templateUrl: './first-finalization-modal.component.html',
  styleUrls: ['./first-finalization-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    DividerComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    NgIf,
    ErrorFormFieldDirective,
    NgxMaskDirective,
    DatepickerDirective,
    SuffixFormFieldDirective,
    DatepickerComponent,
    ModalFooterDirective,
    ButtonComponent,
    LoaderFullpageComponent,
    AsyncPipe,
  ],
})
export class FirstFinalizationModalComponent {
  isLoading = false;
  maxDate = new Date();

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    number: new UntypedFormControl(this.data.invoicer.firstInvoiceNumber, [
      Validators.required,
      Validators.min(1),
      Validators.max(2000000000),
    ]),
    date: new UntypedFormControl(format(new Date(this.data.date), 'dd/MM/yyyy'), [
      Validators.required,
      (control: AbstractControl): ValidationErrors | null =>
        isValid(parse(control.value, 'dd/MM/yyyy', new Date())) ? null : { date: {} },
      (control: AbstractControl): ValidationErrors | null => {
        const parseDate: Date = parse(control.value, 'dd/MM/yyyy', new Date());
        return isValid(parseDate) && (isBefore(parseDate, new Date()) || isEqual(parseDate, new Date()))
          ? null
          : { minDate: {} };
      },
    ]),
  });

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      invoice: SalesInvoiceDraft;
      invoicer: Invoicer;
      date: Date;
      firstName: string;
    },
    private readonly modalRef: ModalRef<SalesInvoiceDraft | null, void>,
    private readonly invoiceStateService: SalesInvoiceStateService,
    private readonly invoicerStateService: SalesInvoicerStateService,
    private readonly metricsService: MetricsService,
    public formService: FormService,
    private readonly cdr: ChangeDetectorRef,
    public readonly companyServicesStateService: CompanyServicesStateService,
  ) {}

  get number(): AbstractControl | null {
    return this.formGroup.get('number');
  }

  get date(): AbstractControl | null {
    return this.formGroup.get('date');
  }

  async finalizeInvoice(): Promise<void> {
    this.isLoading = true;
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const successInvoicerUpdate: boolean = await this.invoicerStateService.updateInvoicer({
        ...this.data.invoicer,
        firstInvoiceNumber: this.number?.value,
      });

      if (successInvoicerUpdate) {
        const successInvoiceUpdate = await this.invoiceStateService.updateInvoice({
          ...this.data.invoice,
          date: parse(this.date?.value, 'dd/MM/yyyy', new Date()),
        });

        if (successInvoiceUpdate) {
          const finalizedInvoice: SalesInvoiceDraft | null = await this.invoiceStateService.finalizeInvoice(
            this.data.invoice,
          );
          if (finalizedInvoice) {
            this.metricsService.pushMixpanelEvent('Invoicing finalised Invoice Success');
            this.metricsService.pushIntercomEvent('Invoicing finalised Invoice Success');
            this.modalRef.close(finalizedInvoice);
          }
        }
      }
    }

    this.isLoading = false;

    this.cdr.markForCheck();
  }
}
