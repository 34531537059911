import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { InvoiceError } from '@dougs/sales-invoice/dto';

@Component({
  selector: 'dougs-finalization-error-modal',
  templateUrl: './finalization-error-modal.component.html',
  styleUrls: ['./finalization-error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class FinalizationErrorModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: { type: 'offer' | 'invoice'; invoiceErrors: InvoiceError[] },
  ) {}
}
