import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, map, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, FlashMessagesService, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { InvoiceError, Invoicer, SalesInvoice, SalesInvoiceDraft, SalesInvoiceType } from '@dougs/sales-invoice/dto';
import { SalesInvoicerStateService, SalesInvoiceStateService } from '@dougs/sales-invoice/shared';
import { UserStateService } from '@dougs/user/shared';
import { FinalizationErrorModalComponent } from '../modals/finalization-error-modal/finalization-error-modal.component';
import { FinalizationModalComponent } from '../modals/finalization-modal/finalization-modal.component';
import { FirstFinalizationModalComponent } from '../modals/first-finalization-modal/first-finalization-modal.component';
import { SalesInvoiceFilterComponentService } from './sales-invoice-filter-component.service';

@Injectable()
export class SalesInvoiceItemComponentService {
  constructor(
    private readonly salesInvoiceStateService: SalesInvoiceStateService,
    private readonly salesInvoicerStateService: SalesInvoicerStateService,
    private readonly userStateService: UserStateService,
    private readonly salesInvoiceFilterComponentService: SalesInvoiceFilterComponentService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly routingService: RoutingService,
    private readonly metricsService: MetricsService,
    @Inject(Window) private readonly window: Window,
  ) {}

  private readonly finalizeInvoiceSubject: Subject<number> = new Subject<number>();
  finalizeInvoice$: Observable<number> = this.finalizeInvoiceSubject.asObservable();

  async duplicateInvoice(invoice: SalesInvoice | SalesInvoiceDraft): Promise<SalesInvoiceDraft | null> {
    const invoiceDuplicated: SalesInvoiceDraft | null = await this.salesInvoiceStateService.duplicateInvoice(invoice);

    if (invoiceDuplicated) {
      this.flashMessagesService.show('La facture a été dupliquée', {
        type: 'success',
        timeout: 5000,
      });
      await this.salesInvoicerStateService.refreshInvoicer(invoiceDuplicated.companyId ?? invoiceDuplicated.invoicerId);
      await this.router.navigateByUrl(
        this.routingService.createUrl([URL.SALES_INVOICE, invoiceDuplicated.id]).toString(),
      );
    }
    return invoiceDuplicated;
  }

  async deleteInvoice(invoice: SalesInvoiceDraft): Promise<void> {
    const toDelete: boolean = await toPromise(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Supprimer la facture',
            body: 'Êtes-vous sûr de vouloir supprimer cette facture ? Cette action est définitive.',
            noText: 'Annuler',
            yesText: 'Oui, supprimer la facture',
          },
        })
        .afterClosed$.pipe(
          take(1),
          map((res: OverlayCloseEvent<unknown>) => !!res.data),
        ),
    );
    if (toDelete && invoice) {
      await this.salesInvoiceStateService.deleteSalesInvoiceDraft(invoice);
    }
  }

  async deleteUploadedFinalizedInvoice(salesInvoice: SalesInvoice): Promise<boolean> {
    if (salesInvoice?.companyId && salesInvoice?.id) {
      const shouldBeDeleted = !!(
        await lastValueFrom(
          this.modalService.open(ConfirmationModalComponent, {
            data: {
              title: 'Supprimer la facture',
              body: `Votre facture et toutes les modifications apportées seront perdues.`,
              noText: 'Annuler',
              yesText: 'Supprimer la facture',
            },
          }).afterClosed$,
        )
      ).data;
      if (!shouldBeDeleted) {
        return false;
      }
      await this.salesInvoiceStateService.deleteSalesInvoice(salesInvoice.companyId, salesInvoice.id);
      await this.salesInvoiceStateService.refreshInvoiceStat(salesInvoice.companyId);
      return true;
    }
    return false;
  }

  async downloadOffer(invoice: SalesInvoiceDraft): Promise<void> {
    const invoiceErrors: InvoiceError[] | null = await this.salesInvoiceStateService.canFinalizeOffer(invoice);
    if (invoiceErrors && invoiceErrors.length > 0) {
      this.modalService.open(FinalizationErrorModalComponent, {
        data: { type: 'offer', invoiceErrors },
      });
    } else {
      this.metricsService.pushMixpanelEvent('Invoicing Offer downloaded', { Type: 'Offer' });
      this.metricsService.pushIntercomEvent('Invoicing Offer downloaded', { Type: 'Offer' });
      this.window.open(
        `/companies/${invoice.companyId ?? invoice.invoicerId}/sales-invoices-drafts/${
          invoice.id
        }/actions/download-offer`,
        '_blank',
      );
    }
  }

  async finalizeInvoice(invoice: SalesInvoiceDraft): Promise<void> {
    this.finalizeInvoiceSubject.next(new Date().getTime());
    const invoiceErrors: InvoiceError[] | null = await this.salesInvoiceStateService.canFinalizeInvoice(invoice);
    const invoicer: Invoicer = await lastValueFrom(this.salesInvoicerStateService.invoicer$.pipe(take(1)));

    this.trackFinalizeErrors(invoiceErrors);

    const finalizedInvoiceDraft: SalesInvoiceDraft | null | undefined = await this.handleFinalizeModal(
      invoice,
      invoicer,
      invoiceErrors,
    );
    if (finalizedInvoiceDraft?.salesInvoiceId) {
      this.salesInvoiceFilterComponentService.setInvoiceType(SalesInvoiceType.FINALIZED);
      await this.router.navigateByUrl(
        this.routingService
          .createUrl([URL.SALES_INVOICE], true, {
            queryParams: { salesInvoiceId: finalizedInvoiceDraft?.salesInvoiceId },
          })
          .toString(),
      );
    }
  }

  downloadPDF(invoice: SalesInvoice | SalesInvoiceDraft | null): void {
    if (invoice) {
      this.metricsService.pushMixpanelEvent('Invoicing PDF downloaded', {
        Type: invoice.isDraft ? 'Draft' : 'Invoice',
      });
      this.metricsService.pushIntercomEvent('Invoicing PDF downloaded', {
        Type: invoice.isDraft ? 'Draft' : 'Invoice',
      });
      const downloadUrl: string = invoice.isDraft
        ? `/companies/${invoice.companyId}/sales-invoices-drafts/${invoice.id}/actions/download`
        : `${(invoice as SalesInvoice).filePath}`;
      this.window.open(downloadUrl, '_blank');
    }
  }

  private trackFinalizeErrors(invoiceErrors: InvoiceError[] | null): void {
    if (invoiceErrors && invoiceErrors.length > 0) {
      this.metricsService.pushMixpanelEvent('Invoicing finalised Invoice failed', {
        'Failed reasons': invoiceErrors.map((error) => error.message),
      });

      this.metricsService.pushIntercomEvent('Invoicing finalised Invoice failed', {
        'Failed reasons': invoiceErrors.map((error) => error.message),
      });
    }
  }

  private async handleFinalizeModal(
    salesInvoiceDraft: SalesInvoiceDraft,
    invoicer: Invoicer,
    invoiceErrors: InvoiceError[] | null,
  ): Promise<SalesInvoiceDraft | null | undefined> {
    let finalizedInvoiceDraft: SalesInvoiceDraft | null | undefined;
    if (invoiceErrors && invoiceErrors.length > 0) {
      this.modalService.open(FinalizationErrorModalComponent, {
        data: { type: 'invoice', invoiceErrors },
      });
    } else if (invoiceErrors && invoiceErrors.length === 0) {
      if (invoicer.lastIssuedInvoiceNumber) {
        finalizedInvoiceDraft = (
          await toPromise(
            this.modalService
              .open(FinalizationModalComponent, {
                data: {
                  invoice: salesInvoiceDraft,
                  invoiceNumber: invoicer.lastIssuedInvoiceNumber + 1,
                  date: salesInvoiceDraft.date,
                  totalAmount: salesInvoiceDraft.totalAmountWithVat,
                },
              })
              .afterClosed$.pipe(take(1)),
          )
        )?.data as SalesInvoiceDraft | null | undefined;
      } else {
        finalizedInvoiceDraft = (
          await toPromise(
            this.modalService
              .open(FirstFinalizationModalComponent, {
                data: {
                  invoice: salesInvoiceDraft,
                  invoicer,
                  date: salesInvoiceDraft.date,
                  firstName: this.userStateService.activeUser.profile?.firstName,
                },
              })
              .afterClosed$.pipe(take(1)),
          )
        )?.data as SalesInvoiceDraft | null | undefined;
      }
    }
    return finalizedInvoiceDraft;
  }
}
